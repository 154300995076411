.blog__details_div {
  background: #fafaf9;
  width: 100%;
  /* height: 100%; */
  color: black;
  margin: 0;
  padding: 12px;
  display: grid;
  grid-template-columns: 2fr 3fr;
}

.blog__image {
  height: 100vh;
  /* height: 100%; */
}

.blog__image img {
  max-width: 100%;
  height: 100%;
  object-fit: cover;
}

.blog__content {
  background: #fafaf9;
}

.blog__content div.blog__content_Content {
  padding: 8px 90px;
}

.blog__content div {
  text-align: start;
}

.blog__content div p.blog__content_description_title_time {
  font-size: 0.6rem;
  color: rgb(77, 73, 73);
}

.blog__content div p {
  line-height: 1rem;
  font-size: 0.8rem;
  max-width: 55ch;
}

.blog__content div.share_btn_container {
  background: #ffff;
  display: flex;
  flex-direction: column;
  padding: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0 , 0.3);
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
}

.blog__content div.share_btn_container a svg {
  font-size: 32px;
}

.blog__content div.share_btn_container a {
  margin: 12px 0;
  transition: 500ms;
}

.blog__content div.share_btn_container a:hover {
  transform: scale(1.2);
}

.blog__content div.share_btn_container a.facebookIcon {
  color: #3b5998;
}
.blog__content div.share_btn_container a.pinterestIcon {
  color: #bd081c;
}
.blog__content div.share_btn_container a.twitterIcon {
  color: #1da1f2;
}
.blog__content div.share_btn_container a.linkedinIcon {
  color: #0077b5;
}
.blog__content div.share_btn_container a.whatsappIcon {
  color: #128c7e;
}

.blog__content_description p {
  margin: 0;
}

.blog__content_description {
  margin-bottom: 3vw;
}

.blog__content_Content div h1.blog__content_title {
  font-size: 3vw;
}

.blog__content_description p.desription_text {
  line-height: 1.2;
  font-size: 1.8vw;
  max-width: 50ch;
}

.blog__content_body p.body_text, 
.blog__content_body .body_MD_Div p.body_text{
  line-height: 1.4;
  font-size: 1.2vw;
}

.body_MD_Div p > a {
  color: #c83232;
}

.body_MD_Div p > a:hover {
  color: #7e4f4f;
}

.body_MD_Div iframe {
  width: 100%;
  height: 30vh;
}

.blog__content_body .body_MD_Div ul li {
  list-style: inside;
}

.blog__content_Content ul.blogLinks::before {
  content: "RESOURCES";
  font-size: 0.8rem;
  display: block;
  text-align: center;
}

.blog__content_Content ul.blogLinks {
  padding: 0;
}

.blog__content_Content ul.blogLinks li.blogLink {
  list-style: none;
  background: #69779b;
  font-size: 0.4rem;
  color: #fafaf9;
  margin: 1px auto;
  text-align: center;
}

.blog__content_Content ul.blogLinks li.blogLink:hover {
  background: #9fbece;
}

@media (max-width: 850px) {
  .blog__details_div {
    display: grid;
    grid-template-rows: 450px;
    grid-template-columns: none
  }
  
  .blog__image {
    height: 400px;
    width: 90vw;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }
  
  .blog__image img {
    width: 90%;
    height: 100%;
    object-fit: cover;
  }

  .blog__content_Content div h1.blog__content_title {
    font-size: 4vw;
  }
  
  .blog__content_description p.desription_text {
    line-height: 1.2;
    font-size: 2.5vw;
  }
  
  .blog__content_body p.body_text {
    line-height: 1.4;
    font-size: 1.8vw;
  }
}

@media (max-width: 760px) {
  .blog__details_div {
    grid-template-rows: 350px;
  }
  
  .blog__image {
    height: 300px;
    align-self: center;
  }
  
} 

@media (max-width: 560px) {
  .blog__details_div {
    grid-template-rows: 300px;
  }

  .blog__content {
    position: relative;
  }

  .my_carousel_div {
    height: 200px;
    margin-bottom: 60px;
  }

  .blog__content div.blog__content_Content {
    padding: 8px 32px;
  }

  .blog__content div.share_btn_container  {
    position: absolute;
    transform: unset;
    top: unset;
    left: 0;
    bottom: 0;
    width: 100%;
    flex-direction: row;
    box-shadow: 4px 0 8px rgba(0, 0, 0 , 0.3);
    padding: 16px 0;
    justify-content: center;
  }

  .blog__content div.share_btn_container a {
    margin: 0 32px;
  }
  
  .blog__image {
    height: 250px;
    align-self: center;
  }
}

@media (max-width: 450px) {
  .blog__content div.share_btn_container a {
    margin: 0 16px;
  }
}

@media (max-width: 380px) {
  .blog__details_div {
    grid-template-rows: 200px;
  }
  
  .blog__image {
    height: 180px;
    align-self: center;
  }

  .blog__content_Content div h1.blog__content_title {
    font-size: 5.5vw;
  }
  
  .blog__content_description p.desription_text {
    font-size: 4vw;
  }
  
  .blog__content_body p.body_text {
    font-size: 2.5vw;
  }
}