.blogListControls {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0.5rem;
}

.blogListControls button {
  font: inherit;
  border: none;
  background: transparent;
  color: black;
  padding: 0.25rem 3rem;
  border-bottom: 2px solid transparent;
  cursor: pointer;
}

.blogListControls button.active {
  border-bottom-color: #495f97;
  color: #495f97;
}

.blogListControls button:focus {
  outline: none;
}

@media (max-width: 340px) {
  .blogListControls {
    padding: 0;
    margin: 0;
  }
  
  .blogListControls button {
    padding: 4px;
  }
}