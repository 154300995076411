footer {
  padding: 16px 0;
  background: #393e46;
  color: #f0ece2;
  position: fixed;
  bottom: 0;
  width: 100vw;
  font-size: 1.5rem;
}

footer > a {
  color: #69779b;
  margin-left: 0.5rem;
}

footer ul.socials {
  background-color: yellow;
  color: black;
}
footer ul.socials li.center {
  list-style: none;
}

@media (max-width: 340px) {
  footer {
    font-size: 1rem;
  }
}