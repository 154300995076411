.save-btn {
  position: fixed;
  bottom: 100px;
  right: 50px;
  background-color: #fff;
  color: #000 !important;
  padding-left: 15px;
  padding-right: 15px;
  font-weight: bold;
}


.delete-btn {
  color: #b45a5a;
  border: 1px solid #8b0000;
}

.delete-btn:hover {
  background-color: #8b0000;
  color: white;
}

.admin-page p,
.admin-page h1,
.admin-page h2,
.admin-page h3,
.admin-page h4,
.admin-page h5,
.admin-page h6,
.admin-page button,
.admin-page label,
.admin-page textarea {
  color: whitesmoke;
}


button {
  border: 1px solid #fff;
  border-radius: 5px;
  padding: 5px;
}

.admin-page button:hover {
  background-color: #fff;
  color: #000;
}

.admin-page input[type="text"][disabled] {
  color: gray;
}

.admin-page input[type="checkbox"][disabled] {
  color: gray;
}

.w-md-editor-text-pre, .w-md-editor-text-input {
  color: inherit
}