.resumeDiv {
  max-width: 900px;
  margin: 0 auto;
  padding: 24px;
  color: #f0ece2;
}

ul.resumeSkills li, ul.resume_UL li {
  list-style: inside;
}

hr.section {
  height: 30px;
  border-style: solid;
  border-color: #c83232;
  border-width: 1px 0 0 0;
  border-radius: 20px;
}

hr.section:before {
  display: block;
  content: "";
  height: 30px;
  margin-top: -31px;
  border-style: solid;
  border-color: #c83232;
  border-width: 0 0 1px 0;
  border-radius: 20px;
}

hr.experience-separator {
  border: 0;
  height: 0; /* Firefox... */
  box-shadow: 0 0 10px 1px #c83232;
}
hr.experience-separator:after {  /* Not really supposed to work, but does */
  content: "\00a0";  /* Prevent margin collapse */
}

span#address {
  margin: 8px;
}

.resumeHeader {
  margin: 8px;
  padding: 8px;
  border: 1px inset #c83232;
  border-radius: 6px;
}


.summaryPara {
  padding: 16px;
  margin: 8px;
  line-height: 2rem;
}


li {
  margin: 8px;
  padding: 8px;
  list-style: lower-roman;
}

li .verification_link {
  color: #c83232;
}

li .verification_link:hover,  li .verification_link:active, li .verification_link:focus
{
  color: #f0ece2;
}