@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,400;0,500;1,600;1,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Poppins&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700i,800,800i&display=swap);
body {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.toolbar {
  /* position: fixed; */
  top: 0;
  left: 0;
  width: 100%;
  background: #393e46;
  height: 56px;
}

.toolbar__navigation {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  padding: 0 1rem;
}

.toolbar__logo {
  margin-left: 1rem;
}

.toolbar__logo a img {
  color: white;
  text-decoration: none;
  height: 40px;
  border-radius: 50%;
}

.spacer {
  flex: 1 1;
}

.toolbar_navigation_items a {
  color: #f0ece2;
  text-decoration: none;
  font-size: 1.2rem;
  font: inherit;
}

.toolbar_navigation_items a:hover,
.toolbar_navigation_items a:active,
.toolbar_navigation_items a.active {
  background-color: #69779b;
  padding: 8px 16px;
  border: 0.5 solid #010101;
  border-radius: 6px;
  font: inherit;
}

.toolbar_navigation_items ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.toolbar_navigation_items li {
  padding: 0 0.5rem;
  list-style: none;
}

.disabled {
  pointer-events: none;
  color: grey;
}

.disabled:hover,
.disabled:active {
  color: grey;
}

@media (max-width: 768px) {
  .toolbar_navigation_items {
    display: none;
  }
}

@media (min-width: 769px) {
  .toolbar__toggle-button {
    display: none;
  }

  .toolbar__logo {
    margin-left: 0;
  }
}

.toggle-button {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 24px;
  width: 30px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  box-sizing: border-box;
}

.toggle-button:focus {
  outline: none;
}

.toggle-button__line {
  width: 30px;
  height: 2px;
  background: white;
}
.resumeDiv {
  max-width: 900px;
  margin: 0 auto;
  padding: 24px;
  color: #f0ece2;
}

ul.resumeSkills li, ul.resume_UL li {
  list-style: inside;
}

hr.section {
  height: 30px;
  border-style: solid;
  border-color: #c83232;
  border-width: 1px 0 0 0;
  border-radius: 20px;
}

hr.section:before {
  display: block;
  content: "";
  height: 30px;
  margin-top: -31px;
  border-style: solid;
  border-color: #c83232;
  border-width: 0 0 1px 0;
  border-radius: 20px;
}

hr.experience-separator {
  border: 0;
  height: 0; /* Firefox... */
  box-shadow: 0 0 10px 1px #c83232;
}
hr.experience-separator:after {  /* Not really supposed to work, but does */
  content: "\00a0";  /* Prevent margin collapse */
}

span#address {
  margin: 8px;
}

.resumeHeader {
  margin: 8px;
  padding: 8px;
  border: 1px inset #c83232;
  border-radius: 6px;
}


.summaryPara {
  padding: 16px;
  margin: 8px;
  line-height: 2rem;
}


li {
  margin: 8px;
  padding: 8px;
  list-style: lower-roman;
}

li .verification_link {
  color: #c83232;
}

li .verification_link:hover,  li .verification_link:active, li .verification_link:focus
{
  color: #f0ece2;
}
/* .container {
  max-width: 1200px;
  height: 100vh;
  margin: 0 auto;
  padding: 24px;
  color: #f0ece2;
} */

/* .imgDiv {
  display: flex;
  justify-content: center;
  margin: 100px auto;
}

.workInProgress {
  width: 400px;
  height: 200px;
  display: flex;
  align-self: center;

}

@media (max-width: 500px) {
  .workInProgress {
    width: 350px;
    height: 200px;
  }
} */

.Blog_container__3X3pC {
  min-height: 100vh;
  margin: 0;
  padding: 0;
  background: #fafaf9;
  max-width: 1200px;
  color: #495f97;
}

.Blog_blogJumbotron__28L2s {
  display: flex;
  flex-direction: row;
  padding: 2rem;
  width: 80%;
  margin: 0 auto;
  align-items: center;
  justify-content: space-around;
  box-shadow: 0 8px 6px -6px black;
}

.Blog_blogHeaderDiv__3pz-H {
  padding: 1rem;
  text-align: start;
  align-items: center;
}

.Blog_blogHeader__3WdkO {
  font-weight: bold;
  font-style: italic;
}

.Blog_blogProfileImage__1m-Pu {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  box-shadow: 0 8px 6px -6px black;
}

.Blog_blogProfileImageDiv__1d8sR {
  justify-content: center;
  height: 150px;
  width: 150px;
}

.Blog_blogList__rl0tv {
  width: 80%;
  margin: 0 auto;
  padding: 0;
}

.Blog_blog__2uihs {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: #f3f3ed;
  color: #495f97;
  box-shadow: 0 8px 6px -6px black;
}

.Blog_blog__2uihs a div img {
  width: 100px;
  height: 120px;
}

.Blog_blogContentDiv__3OJIU {
  margin: 0;
  padding: 0;
  margin-left: 8px;
}

.Blog_blogContentDiv__3OJIU a {
  color: #495f97;
  cursor: pointer;
}

.Blog_blogContentDiv__3OJIU a:hover, .Blog_blogContentDiv__3OJIU a:active {
  text-decoration: underline;
  color: rgb(77, 73, 73);
}

.Blog_blogContentDiv__3OJIU a h1 {
  font-size: 2rem;
}

.Blog_blogContentDiv__3OJIU p {
  padding: 8px;
  text-align: start;
  padding: 0;
  margin: 0;
  line-height: 1rem;
  font-size: 0.8rem;
}

.Blog_blogContentDiv__3OJIU p.Blog_posted__nKt1G {
  padding: 0;
  margin: 0;
  font-size: 0.6rem;
  color: rgb(77, 73, 73);
}

@media (max-width: 850px) {
  .Blog_container__3X3pC {
    width: 100%;
  }

  .Blog_blogJumbotron__28L2s {
    padding: 4px;
    width: 80vw;
  }

  .Blog_blogHeaderDiv__3pz-H h1.Blog_blogHeader__3WdkO {
    font-size: 2rem;
  }

  .Blog_blogHeaderDiv__3pz-H p {
    line-height: 1rem;
    font-size: 1rem;
  }

  .Blog_blogProfileImage__1m-Pu {
    height: 100px;
    width: 100px;
  }
  .Blog_blogProfileImageDiv__1d8sR {
    justify-content: center;
    height: 100px;
    width: 100px;
  }
  .Blog_blogList__rl0tv {
    width: 80vw;
  }
}

@media (max-width: 390px) {
  .Blog_container__3X3pC {
    width: 100;
  }

  .Blog_blogJumbotron__28L2s {
    padding: 4px;
    width: 100%;
  }

  .Blog_blogHeaderDiv__3pz-H h1.Blog_blogHeader__3WdkO {
    font-size: 1.2rem;
  }

  .Blog_blogHeaderDiv__3pz-H p {
    line-height: 1rem;
    font-size: 0.8rem;
  }

  .Blog_blogProfileImage__1m-Pu {
    height: 80px;
    width: 80px;
  }
  .Blog_blogProfileImageDiv__1d8sR {
    justify-content: center;
    height: 80px;
    width: 80px;
  }
  .Blog_blogList__rl0tv {
    width: 100vw;
  }
  
}

.blogListControls {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0.5rem;
}

.blogListControls button {
  font: inherit;
  border: none;
  background: transparent;
  color: black;
  padding: 0.25rem 3rem;
  border-bottom: 2px solid transparent;
  cursor: pointer;
}

.blogListControls button.active {
  border-bottom-color: #495f97;
  color: #495f97;
}

.blogListControls button:focus {
  outline: none;
}

@media (max-width: 340px) {
  .blogListControls {
    padding: 0;
    margin: 0;
  }
  
  .blogListControls button {
    padding: 4px;
  }
}
.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  -webkit-animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
          animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s;
}
@-webkit-keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

.BlogDetails_blog__details_div__249FS {
  background: #fafaf9;
  width: 100%;
  /* height: 100%; */
  color: black;
  margin: 0;
  padding: 12px;
  display: grid;
  grid-template-columns: 2fr 3fr;
}

.BlogDetails_blog__image__iRlUy {
  height: 100vh;
  /* height: 100%; */
}

.BlogDetails_blog__image__iRlUy img {
  max-width: 100%;
  height: 100%;
  object-fit: cover;
}

.BlogDetails_blog__content__1ZZIy {
  background: #fafaf9;
}

.BlogDetails_blog__content__1ZZIy div.BlogDetails_blog__content_Content__2Pa8H {
  padding: 8px 90px;
}

.BlogDetails_blog__content__1ZZIy div {
  text-align: start;
}

.BlogDetails_blog__content__1ZZIy div p.BlogDetails_blog__content_description_title_time__1FEQk {
  font-size: 0.6rem;
  color: rgb(77, 73, 73);
}

.BlogDetails_blog__content__1ZZIy div p {
  line-height: 1rem;
  font-size: 0.8rem;
  max-width: 55ch;
}

.BlogDetails_blog__content__1ZZIy div.BlogDetails_share_btn_container__3or_n {
  background: #ffff;
  display: flex;
  flex-direction: column;
  padding: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0 , 0.3);
  position: fixed;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.BlogDetails_blog__content__1ZZIy div.BlogDetails_share_btn_container__3or_n a svg {
  font-size: 32px;
}

.BlogDetails_blog__content__1ZZIy div.BlogDetails_share_btn_container__3or_n a {
  margin: 12px 0;
  transition: 500ms;
}

.BlogDetails_blog__content__1ZZIy div.BlogDetails_share_btn_container__3or_n a:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.BlogDetails_blog__content__1ZZIy div.BlogDetails_share_btn_container__3or_n a.BlogDetails_facebookIcon__24C-j {
  color: #3b5998;
}
.BlogDetails_blog__content__1ZZIy div.BlogDetails_share_btn_container__3or_n a.BlogDetails_pinterestIcon__1Uo0R {
  color: #bd081c;
}
.BlogDetails_blog__content__1ZZIy div.BlogDetails_share_btn_container__3or_n a.BlogDetails_twitterIcon__VNdRw {
  color: #1da1f2;
}
.BlogDetails_blog__content__1ZZIy div.BlogDetails_share_btn_container__3or_n a.BlogDetails_linkedinIcon__3YiFo {
  color: #0077b5;
}
.BlogDetails_blog__content__1ZZIy div.BlogDetails_share_btn_container__3or_n a.BlogDetails_whatsappIcon__b03_l {
  color: #128c7e;
}

.BlogDetails_blog__content_description__762HQ p {
  margin: 0;
}

.BlogDetails_blog__content_description__762HQ {
  margin-bottom: 3vw;
}

.BlogDetails_blog__content_Content__2Pa8H div h1.BlogDetails_blog__content_title__2tIrT {
  font-size: 3vw;
}

.BlogDetails_blog__content_description__762HQ p.BlogDetails_desription_text__mTGIu {
  line-height: 1.2;
  font-size: 1.8vw;
  max-width: 50ch;
}

.BlogDetails_blog__content_body__3_0rB p.BlogDetails_body_text__30BBb, 
.BlogDetails_blog__content_body__3_0rB .BlogDetails_body_MD_Div__2x8gu p.BlogDetails_body_text__30BBb{
  line-height: 1.4;
  font-size: 1.2vw;
}

.BlogDetails_body_MD_Div__2x8gu p > a {
  color: #c83232;
}

.BlogDetails_body_MD_Div__2x8gu p > a:hover {
  color: #7e4f4f;
}

.BlogDetails_body_MD_Div__2x8gu iframe {
  width: 100%;
  height: 30vh;
}

.BlogDetails_blog__content_body__3_0rB .BlogDetails_body_MD_Div__2x8gu ul li {
  list-style: inside;
}

.BlogDetails_blog__content_Content__2Pa8H ul.BlogDetails_blogLinks__-hqWG::before {
  content: "RESOURCES";
  font-size: 0.8rem;
  display: block;
  text-align: center;
}

.BlogDetails_blog__content_Content__2Pa8H ul.BlogDetails_blogLinks__-hqWG {
  padding: 0;
}

.BlogDetails_blog__content_Content__2Pa8H ul.BlogDetails_blogLinks__-hqWG li.BlogDetails_blogLink__ltlol {
  list-style: none;
  background: #69779b;
  font-size: 0.4rem;
  color: #fafaf9;
  margin: 1px auto;
  text-align: center;
}

.BlogDetails_blog__content_Content__2Pa8H ul.BlogDetails_blogLinks__-hqWG li.BlogDetails_blogLink__ltlol:hover {
  background: #9fbece;
}

@media (max-width: 850px) {
  .BlogDetails_blog__details_div__249FS {
    display: grid;
    grid-template-rows: 450px;
    grid-template-columns: none
  }
  
  .BlogDetails_blog__image__iRlUy {
    height: 400px;
    width: 90vw;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }
  
  .BlogDetails_blog__image__iRlUy img {
    width: 90%;
    height: 100%;
    object-fit: cover;
  }

  .BlogDetails_blog__content_Content__2Pa8H div h1.BlogDetails_blog__content_title__2tIrT {
    font-size: 4vw;
  }
  
  .BlogDetails_blog__content_description__762HQ p.BlogDetails_desription_text__mTGIu {
    line-height: 1.2;
    font-size: 2.5vw;
  }
  
  .BlogDetails_blog__content_body__3_0rB p.BlogDetails_body_text__30BBb {
    line-height: 1.4;
    font-size: 1.8vw;
  }
}

@media (max-width: 760px) {
  .BlogDetails_blog__details_div__249FS {
    grid-template-rows: 350px;
  }
  
  .BlogDetails_blog__image__iRlUy {
    height: 300px;
    align-self: center;
  }
  
} 

@media (max-width: 560px) {
  .BlogDetails_blog__details_div__249FS {
    grid-template-rows: 300px;
  }

  .BlogDetails_blog__content__1ZZIy {
    position: relative;
  }

  .BlogDetails_my_carousel_div__X2T5u {
    height: 200px;
    margin-bottom: 60px;
  }

  .BlogDetails_blog__content__1ZZIy div.BlogDetails_blog__content_Content__2Pa8H {
    padding: 8px 32px;
  }

  .BlogDetails_blog__content__1ZZIy div.BlogDetails_share_btn_container__3or_n  {
    position: absolute;
    -webkit-transform: unset;
            transform: unset;
    top: unset;
    left: 0;
    bottom: 0;
    width: 100%;
    flex-direction: row;
    box-shadow: 4px 0 8px rgba(0, 0, 0 , 0.3);
    padding: 16px 0;
    justify-content: center;
  }

  .BlogDetails_blog__content__1ZZIy div.BlogDetails_share_btn_container__3or_n a {
    margin: 0 32px;
  }
  
  .BlogDetails_blog__image__iRlUy {
    height: 250px;
    align-self: center;
  }
}

@media (max-width: 450px) {
  .BlogDetails_blog__content__1ZZIy div.BlogDetails_share_btn_container__3or_n a {
    margin: 0 16px;
  }
}

@media (max-width: 380px) {
  .BlogDetails_blog__details_div__249FS {
    grid-template-rows: 200px;
  }
  
  .BlogDetails_blog__image__iRlUy {
    height: 180px;
    align-self: center;
  }

  .BlogDetails_blog__content_Content__2Pa8H div h1.BlogDetails_blog__content_title__2tIrT {
    font-size: 5.5vw;
  }
  
  .BlogDetails_blog__content_description__762HQ p.BlogDetails_desription_text__mTGIu {
    font-size: 4vw;
  }
  
  .BlogDetails_blog__content_body__3_0rB p.BlogDetails_body_text__30BBb {
    font-size: 2.5vw;
  }
}
.BlogCarousel_blog__carousel_div__z4dhd {
  height: 150px;
}

.BlogCarousel_blog__carousel_div_image_div__1EKbC {
  width: 300px;
  background: #dadad7;
  padding: 2px;
  height: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.BlogCarousel_blog__carousel_div_image__3oRB3 {
  width: 100%;
  height: 120px;
  object-fit: cover;
}

.BlogCarousel_blog__carousel_div_title__2RQ_p h5 {
  font-size: 0.5rem;
  line-height: 1rem;
  color: rgb(77, 73, 73);
}

.about__me-div {
  max-width: 900px;
  margin: 5px auto;
  padding: 0;
  color: #f0ece2;
  padding: 12px;
}

.about__me-div-quote blockquote {
  background: #f9f9f9;
  border-left: 10px solid #ccc;
  color: #222831;
  margin: 1.5em 10px;
  padding: 0.5em 10px;
  quotes: "\201C""\201D""\2018""\2019";
}

.about__me-div-quote blockquote:before {
  color: #222831;
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}

.about__me-div-quote blockquote:after {
  color: #222831;
  content: close-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-left: 0.25em;
  vertical-align: -0.4em;
}
.about__me-div-quote blockquote p {
  display: inline;
}

.about__me-div-quote blockquote p a {
  color: #c83232;
}

.about__me-div-more p{
  padding: 8px;
  text-align: start;
}
.edit-data {
  position: fixed;
  bottom: 100px;
  right: 50px;
  background-color: #fff;
  color: #000;
  padding-left: 15px;
  padding-right: 15px;
  font-weight: bold;
  border-radius: 5px;
}

.post-blog{
  position: fixed;
  bottom: 100px;
  right: 170px;
  background-color: #fff;
  color: #000;
  padding-left: 15px;
  padding-right: 15px;
  font-weight: bold;
  border-radius: 5px;
  z-index: 1;
}

.myJumbotron {
  width: 95%;
  margin: 0px auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-auto-rows: 300px;
  padding-top: 5px;
}

.jumbotronItem {
  background: #393e46;
  color: #f0ece2;
  padding: 8px;
}

.profileImgContainer {
  width: 100%;
  overflow: hidden;
  padding: 8px;
  display: flex;
  justify-content: center;
}

.profilePic {
  height: 280px;
  width: 280px;
  border: none;
  border-radius: 50%;
}

/* .jumbo-aboutme {
  padding: 5px;
  margin: 5px;
} */

.jumboButtons {
  padding: 5px;
  margin: 5px;
}

.spanButton {
  list-style: none;
  background-color: #69779b;
  padding: 4px 8px;
  margin: 3px;
  border: 0.5 solid #010101;
  border-radius: 6px;
}

a {
  color: #f0ece2;
  text-decoration: none;
}

.spanButton:hover,
.spanButton:active {
  background: #f0ece2;
  color: #c83232;
}

a:hover,
a:active {
  color: #c83232;
  text-decoration: none;
}

.home-section-header,
.contactMessage,
.jumboHeader {
  padding: 8px;
  font-weight: 800;
  color: #c83232;
}

.skillsDiv {
  width: 95%;
  margin: 5px auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-auto-rows: 500px;
}

.skills {
  padding: 8px;
  background: #f0ece2;
  color: #393e46;
}

ul.skills-qualif-list {
  padding: 10px;
  background: rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 15px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  overflow: hidden;
}

ul.skills-qualif-list li {
  display: flex;
  background: #e0896c;
  border-radius: 6px;
  color: #f0ece2;
  padding: 10px 20px;
  margin: 5px 0;
  transition: 2s;
}

ul.skills-qualif-list li:nth-child(1) {
  background: #df7f5f;
}
ul.skills-qualif-list li:nth-child(2) {
  background: #db7350;
}
ul.skills-qualif-list li:nth-child(3) {
  background: #dd6943;
}
ul.skills-qualif-list li:nth-child(4) {
  background: #da5b31;
}
ul.skills-qualif-list li:nth-child(5) {
  background: #d65428;
}
ul.skills-qualif-list li:nth-child(6) {
  background: #b63e16;
}

ul.skills-qualif-list li .framework-skills {
  margin-left: auto;
}

ul.skills-qualif-list li:hover,
ul.skills-qualif-list li:active {
  /* transform: scale(1.2); */
  background: #dab4a7;
  color: #393e46;
  /* color: #b63e16; */
}

.qualifications {
  padding: 8px;
  background: #393e46;
  color: #f0ece2;
}

.projectsDiv {
  margin: 5px auto;
  background: #393e46;
  color: #f0ece2;
  width: 95%;
}

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.box {
  position: relative;
  width: 300px;
  height: 400px;
  background: #f0ece2;
  box-shadow: 0 30px 30px rgba(0, 0, 0, 0.5);
}

.box .imgBx {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.box .imgBx img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 0.5s;
}

.box:hover .imgBx img,
.box:active .imgBx img {
  opacity: 0;
}

.box .content {
  position: absolute;
  bottom: 20px;
  left: 10%;
  width: 80%;
  height: 60px;
  background: #f0ece2;
  transition: 0.5s;
  overflow: hidden;

  padding: 15px;
  box-sizing: border-box;
}

.box:hover .content,
.box:active .content {
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  color: black;
}

.box .content h3 {
  margin: 0;
  padding: 0;
  font-size: 20px;
}

.box .content p {
  margin: 10px 0 0;
  padding: 0;
  opacity: 0;
  line-height: 1.2em;
  transition: 0.5s;
  text-align: justify;
}

.box:hover .content p,
.box:active .content p {
  opacity: 1;
  transition-delay: 0.5s;
}

/* .app-features {
  display: flex;
  align-items: center;
} */

div.app-features h6 {
  text-align: center;
  background: #d48e8e;
  color: #f0ece2;
  margin: 4px;
  padding: 4px;
  box-shadow: inset 0 0 15px rgba(173, 96, 44, 0.2);
  border-radius: 5px;
  overflow: hidden;
}

ul.app-features-list {
  padding: 4px;
  background: rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 15px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  overflow: hidden;
}

ul.app-features-list li {
  display: flex;
  background: #b85656;
  border-radius: 6px;
  color: #f0ece2;
  font-size: 12px;
  padding: 4px 8px;
  margin: 2px 0;
  transition: 5s;
}

ul.skills-qualif-list li:nth-child(1) {
  background: #d48787;
}
ul.skills-qualif-list li:nth-child(2) {
  background: #cf6f6f;
}
ul.skills-qualif-list li:nth-child(3) {
  background: #b85656;
}
ul.skills-qualif-list li:nth-child(4) {
  background: #c45757;
}
ul.skills-qualif-list li:nth-child(5) {
  background: #c44444;
}
ul.skills-qualif-list li:nth-child(6) {
  background: #c83232;
}

div.tech-used {
  display: flex;
  line-height: 1rem;
  width: 100vw;
}

div.tech-used ul {
  list-style: none;
  padding: 0;
}

div.tech-used ul li {
  margin: 0;
  padding: 4px;
  display: inline;
  font-size: 20px;
  background: #f0ece2;
}

div.tech-used ul li:nth-child(1) {
  background: #d48787;
  color: #f0ece2;
}
div.tech-used ul li:nth-child(2) {
  background: #cf7979;
  color: #f0ece2;
}
div.tech-used ul li:nth-child(3) {
  background: #c06464;
  color: #f0ece2;
}
div.tech-used ul li:nth-child(4) {
  background: #c45757;
  color: #f0ece2;
}
div.tech-used ul li:nth-child(5) {
  background: #c44444;
  color: #f0ece2;
}
div.tech-used ul li:nth-child(6) {
  background: #c83232;
  color: #f0ece2;
}

.projects {
  width: 95%;
  margin: 5px auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-auto-rows: 420px;
  color: #393e46;
}

.project {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  font-family: "Poppons", sans-serif;
  cursor: pointer;
  background: #f0ece2;
  color: #393e46;
}

.blog__div {
  margin: 5px auto;
  background: #393e46;
  color: #f0ece2;
  width: 95%;
}

.blog_list {
  width: 95%;
  margin: 5px auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-auto-rows: 420px;
  color: #393e46;
}

.blog_list .blog {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  font-family: "Poppons", sans-serif;
  cursor: pointer;
  background: #f0ece2;
  color: #393e46;
}

.blog_list .blog .blog_img {
  height: 320px;
  margin-bottom: 5px;
  object-fit: cover;
}

.blog_list .blog p.blog__title {
  color: #393e46;
  font-size: 1rem;
  font-weight: bold;
}

#contact {
  box-sizing: border-box;
  background: #393e46;
  color: #393e46;
  line-height: 1.6;
  padding: 1em;
  max-width: 95%;
  margin: 5px auto;
}

div.contactWrapper {
  box-shadow: inset 0 0 15px rgba(0, 0, 0, 0.2);
}

div.contactWrapper > * {
  padding: 1em;
}

div.myInfo {
  background: #69779b;
  color: #f0ece2;
  margin: 0;
}

#contact div div ul li {
  list-style: none;
  margin: 0;
  padding: 0;
}

div.myInfo h5 {
  text-align: center;
  /* margin: 0 0 1rem 0; */
  margin: 0;
}

div.myInfo ul {
  text-align: center;
  /* margin: 0 0 1rem 0; */
  padding: 0;
  margin: 0;
}

div.contactForm {
  background: #f0ece2;
}

div.contactForm form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

div.contactForm form label {
  display: block;
}

div.contactForm form p {
  margin: 0;
}

div.contactForm form .full {
  grid-column: 1 / 3;
}

div.contactForm form button,
div.contactForm form input {
  width: 80%;
  padding: 1em;
  border: 1px solid #69779b;
}

div.contactForm form textarea {
  width: 90%;
  padding: 1em;
  border: 1px solid #69779b;
}

div.contactForm form button {
  background: #69779b;
  color: #f0ece2;
  border: 0;
  text-transform: uppercase;
  border-radius: 6px;
}

div.contactForm form button:hover,
div.contactForm form button:focus {
  background: #f0ece2;
  color: #c83232;
  outline: 0;
  transition: background-color 2s ease-out;
}

.PSA {
  padding: 4px;
  font-weight: bold;
}

@media (max-width: 1110px) {
  .myJumbotron {
    grid-auto-rows: 400px;
  }
  div.tech-used {
    line-height: 0.5rem;
  }

  div.tech-used ul li {
    padding: 2px;
    font-size: 15px;
  }
  .jumboButtons {
    padding: 2px;
    margin: 3px;
  }

  .spanButton {
    padding: 4px 8px;
    margin: 3px;
  }
}
@media (max-width: 950px) {
  .skillsDiv {
    grid-template-rows: 600px;
  }
}

@media (max-width: 850px) {
  .projects {
    grid-template-columns: 350px;
    background: #f0ece2;
    justify-content: center;
  }

  .myJumbotron {
    grid-auto-rows: 420px;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  }
}

@media (max-width: 340px) {
  .projects {
    grid-template-columns: 300px;
    background: #f0ece2;
    justify-content: center;
  }

  .myJumbotron {
    grid-auto-rows: 480px;
    grid-template-columns: repeat(auto-fit, minmax(310px, 1fr));
  }
}

.save-btn {
  position: fixed;
  bottom: 100px;
  right: 50px;
  background-color: #fff;
  color: #000 !important;
  padding-left: 15px;
  padding-right: 15px;
  font-weight: bold;
}


.delete-btn {
  color: #b45a5a;
  border: 1px solid #8b0000;
}

.delete-btn:hover {
  background-color: #8b0000;
  color: white;
}

.admin-page p,
.admin-page h1,
.admin-page h2,
.admin-page h3,
.admin-page h4,
.admin-page h5,
.admin-page h6,
.admin-page button,
.admin-page label,
.admin-page textarea {
  color: whitesmoke;
}


button {
  border: 1px solid #fff;
  border-radius: 5px;
  padding: 5px;
}

.admin-page button:hover {
  background-color: #fff;
  color: #000;
}

.admin-page input[type="text"][disabled] {
  color: gray;
}

.admin-page input[type="checkbox"][disabled] {
  color: gray;
}

.w-md-editor-text-pre, .w-md-editor-text-input {
  color: inherit
}
.App__main {
  max-width: 1200px;
  margin: 0 auto;
  margin-bottom: 65px;
  background: #222831;
}
.side-drawer {
  height: 100%;
  background: #010101;
  box-shadow: 2px 0px 7px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 70%;
  max-width: 400px;
  z-index: 200;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
}

.side-drawer.open {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

.side-drawer ul {
  height: 100%;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.side-drawer li {
  margin: 0.5rem 0;
  list-style: none;
}
.side-drawer a {
  color: #f0ece2;
  text-decoration: none;
  font-size: 1.2rem;
  font: inherit;
}

.side-drawer a:hover,
.side-drawer a:active,
.side-drawer a.active {
  background-color: #69779b;
  padding: 8px 16px;
  border: 0.5 solid #010101;
  border-radius: 6px;
  font: inherit;
}

@media (min-width: 769px) {
  .side-drawer {
    display: none;
  }
}

.backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 100;
}

footer {
  padding: 16px 0;
  background: #393e46;
  color: #f0ece2;
  position: fixed;
  bottom: 0;
  width: 100vw;
  font-size: 1.5rem;
}

footer > a {
  color: #69779b;
  margin-left: 0.5rem;
}

footer ul.socials {
  background-color: yellow;
  color: black;
}
footer ul.socials li.center {
  list-style: none;
}

@media (max-width: 340px) {
  footer {
    font-size: 1rem;
  }
}
.App {
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  background: #222831;
}
